<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lí hướng dẫn thanh toán
      </h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogGuide"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm hướng dẫn</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nội dung</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(guide, i) in guides">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          style="
                            max-width: max-content;
                            max-height: 300px;
                            overflow: hidden;
                          "
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-html="guide.content"
                        ></p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ guide.payment_method_label }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editGuide(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDeleteGuide(guide.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!guides.length">
                    <tr>
                      <td style="text-align: center" colspan="8">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="diaLogGuide" max-width="1400px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'">Thêm mục</span>
              <span class="headline" v-else>Sửa mục</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="diaLogGuide = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Phương thức thanh toán:</label
                      ></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        v-model="payment_method_input"
                        :items="payment_methods"
                        item-value="value"
                        item-text="label"
                        dense
                        filled
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Nội dung:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><Editor v-model="content_input"></Editor
                    ></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditGuide(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditGuide(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="diaLogGuide = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import Editor from "@/components/ckeditor5/Editor";
import Swal from "sweetalert2";
export default {
  name: "List",
  components: {
    Editor,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      diaLogGuide: false,
      guides: [],
      type_form: null,
      content_input: "",
      payment_method_input: null,
      payment_methods: [],
      guide_id: null,
    };
  },
  created() {
    this.getAllGuide();
    this.getAllPaymentMethod();
  },
  methods: {
    async getAllGuide() {
      let vm = this;
      await ApiService.get("prep-app/payment-method-guide").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.guides = response.data;
        }
      });
    },
    async getAllPaymentMethod() {
      let vm = this;
      await ApiService.get(
        "prep-app/payment-method-guide/listPaymentMethod"
      ).then(function (response) {
        if (response.status === 200) {
          vm.payment_methods = response.data;
        }
      });
    },
    openDiaLogGuide() {
      this.type_form = "add";
      this.payment_method_input = null;
      this.content_input = "";
      this.diaLogGuide = true;
    },
    async btnAddOrEditGuide(type) {
      let vm = this;
      let data = {
        payment_method: this.payment_method_input,
        content: this.content_input,
      };
      if (type === 1) {
        await ApiService.post("prep-app/payment-method-guide", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllGuide();
              vm.$toasted.success("Tạo thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.diaLogGuide = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        await ApiService.put(
          "prep-app/payment-method-guide/" + this.guide_id,
          data
        )
          .then(function (res) {
            if (res.status === 202) {
              vm.getAllGuide();
              vm.$toasted.success("Sửa thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.diaLogGuide = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    editGuide(index) {
      let data = this.guides[index];
      this.type_form = "update";
      this.guide_id = data.id;
      this.payment_method_input = data.payment_method;
      this.content_input = data.content == null ? "" : data.content;
      this.diaLogGuide = true;
    },
    btnDeleteGuide(guide_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/payment-method-guide/" + guide_id)
            .then(function (res) {
              if (res.status === 202) {
                vm.getAllGuide();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
